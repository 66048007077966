import { filter, includes } from "lodash";

import {
  DELETING_DATAAPP_MSG,
  LAUNCHING_DATAAPP_MSG,
  STOPPING_DATAAPP_MSG
} from "./DataApps.constants";
import { DataAppDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";
import { DataAppType } from "../DataApps.type";

const deleteTooltip = (status?: DataAppDtoLaunchStatusEnum) => {
  switch (status) {
    case DataAppDtoLaunchStatusEnum.Deleting:
      return DELETING_DATAAPP_MSG;
    case DataAppDtoLaunchStatusEnum.Launching:
      return LAUNCHING_DATAAPP_MSG;
    case DataAppDtoLaunchStatusEnum.Stopping:
      return STOPPING_DATAAPP_MSG;
    default:
      return "";
  }
};

const deleteDisabled = (status?: DataAppDtoLaunchStatusEnum) => {
  return includes(
    [
      DataAppDtoLaunchStatusEnum.Stopping,
      DataAppDtoLaunchStatusEnum.Launching,
      DataAppDtoLaunchStatusEnum.Deleting
    ],
    status
  );
};

export const filterWithSpecificDataApp = (dataApps: DataAppType[], userEmail: string) => {
  const ids = [
    "68b4f16f-7728-4eda-bdb5-bdf6c3ed63aa", // staging
    "82a8d4df-83c2-4690-88ca-75db271ee87c" //test
  ];

  if (userEmail === "andrea@bestpetsupplies.com" || userEmail?.split("@")[1] === "rapidcanvas.ai") {
    return dataApps;
  }
  return filter(dataApps, (dataApp) => !includes(ids, dataApp.id));
};

export { deleteTooltip, deleteDisabled };
